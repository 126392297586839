import React from 'react';
import _ from 'lodash';
import { notification } from 'antd';
import { convertFromRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import moment from 'moment';

import ErrorIcon from 'components/Icons/ErrorIcon';
import Languages, { RTL_LANGUAGES } from 'constants/languages';
import countries_code from 'constants/countries_code';
import language_code from 'constants/language_code';
import { userProfileVar } from 'graphql/cache';
import { RESTRICTED_PAGES } from 'constants/index';
import helpers from 'helpers';

// const openRoutes = ['/cd/', '/PDF/', '/reset-password/', 'login', '/signup', '/roxy'];

/* eslint-disable prefer-destructuring */
const dataURItoBlob = (dataURI) => {
  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString;
  if (dataURI.split(',')[0].indexOf('base64') >= 0) byteString = atob(dataURI.split(',')[1]);
  else byteString = _.escapeRegExp(dataURI.split(',')[1]);
  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  // write the bytes of the string to a typed array
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ia], {
    type: mimeString,
  });
};

const getURLParam = (name) => {
  return (
    decodeURIComponent(
      (new RegExp(`[?|&]${name}=([^&;]+?)(&|#|;|$)`).exec(window.location.search) || [
        null,
        '',
      ])[1].replace(/\+/g, '%20')
    ) || null
  );
};

const isSafari = () => {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
};

const createCookie = (name, value, days = 365) => {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toUTCString()}`;
  }
  document.cookie = `${name}=${value}${expires}; secure; path=/`;
};

const readCookie = (name) => {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

const deleteCookie = (name) => {
  document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
};

/*eslint-disable*/
var getQueryStringValue = function (key) {
  const queryProxy = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  return queryProxy[key];
};

// used in some parent components, makes sure to redirect the user if not connected
// and moved to dashboard if is connected
const loginredirect = function (user, history) {
  const href = location.href;
  const pathName = history.location.pathname;

  if (!user) {
    /*
     * Do nothing
     *
    if (!new RegExp('\\b' + openRoutes.join('\\b|\\b') + '\\b').test(href)) {
      console.log('login redirect');
      history.push({
        pathname: '/login',
      });
    }
    */
    userProfileVar(null);
  } else {
    const redirectUrl = sessionStorage.getItem('redirect-url') || getURLParam('redirect_url');

    if (redirectUrl) {
      return history.push(redirectUrl);
    }

    if (
      pathName === '/wix-app-connect' ||
      pathName.includes('/proposal-editor') ||
      pathName.includes('/template-editor')
    ) {
      // do nothing
    } else if (pathName === '/terms') {
      if (href.indexOf('dashboard') === -1) {
        console.log('terms redirect');
        history.push({
          pathname: '/terms',
        });
      }
    } else if (!user?.profile?.terms) {
      history.push({
        pathname: `/dashboard`,
        search: history?.location?.search,
      });
    } else if (user?.paymentStatus?.canLock) {
      if (RESTRICTED_PAGES.some((page) => pathName.includes(page))) {
        history.push({
          pathname: '/dashboard',
        });
      }
    }
    // else if (user && user.profile && user.profile.role === 'affiliate') {
    //   history.push({
    //     pathname: '/affiliate',
    //   });
    // }
    else if (history.location.pathname === '/login' || history.location.pathname === '/signup') {
      history.push({
        pathname: '/dashboard',
      });
    } else if (history.location.pathname === '/') {
      history.push({
        pathname: '/dashboard',
      });
    }
  }
};
/* eslint-enable */

/* Add one or more listeners to an element
 ** @param {DOMElement} element - DOM element to add listeners to
 ** @param {string} eventNames - space separated list of event names, e.g. 'click change'
 ** @param {Function} listener - function to attach for each event as a listener
 */
const addListenerMulti = (element, eventNames, listener) => {
  const events = eventNames.split(' ');
  for (let i = 0, iLen = events.length; i < iLen; i++) {
    element.addEventListener(events[i], listener, false);
  }
};

const fetchIp = async () => {
  try {
    // alternate api
    // let result = await fetch('https://ipinfo.io/json?token=64b9b988e39606');
    // return await result.json();

    let result = await fetch('https://www.cloudflare.com/cdn-cgi/trace');
    result = await result.text();
    return helpers.transformReadableText(result);
  } catch (error) {
    console.error(error.message);
  }
};

const cacheGeoData = () => {
  fetchIp()
    .then((result) => {
      if (result?.ip) {
        fetch(`https://json.geoiplookup.io/${result.ip}`)
          .then((res) => res.json())
          .then((result2) => {
            if (result2?.country_code) {
              let regionalLanguage =
                countries_code[result2?.country_code]?.languages[0]?.toLowerCase() || 'english';

              if (regionalLanguage === 'pt' && result2?.country_code === 'BR') {
                regionalLanguage = 'portuguese (BR)';
              } else if (regionalLanguage === 'pt') {
                regionalLanguage = 'portuguese (PT)';
              } else {
                regionalLanguage =
                  language_code[regionalLanguage]?.name?.toLowerCase() || 'english';
              }

              const defaultLanguage = Languages.find((lang) => regionalLanguage === lang.value);

              regionalLanguage = defaultLanguage ? defaultLanguage.text : 'english';
              window.sessionStorage.setItem(
                'country.value',
                countries_code[result2?.country_code]?.name
              );
              window.sessionStorage.setItem('countryCode.value', result2?.country_code);
              window.sessionStorage.setItem('language.value', regionalLanguage);
              window.sessionStorage.setItem('ip', result.ip);
            }
          })
          .catch((error) => console.error(error.message));
      }
    })
    .catch((error) => console.error(error.message));
};

const showErrorMessage = ({ title, message, onClose }) => {
  return notification.open({
    onClose,
    className: 'custom-error-notification',
    placement: 'bottomRight',
    message: (
      <div style={{ color: '#C10400' }}>
        <h5 style={{ color: '#C10400', fontSize: '18px', fontWeight: 600 }}>{title}</h5>
        <div className="ant-notification-notice-description" style={{ marginLeft: 0 }}>
          {message}
        </div>
      </div>
    ),
    closeIcon: null,
    icon: <ErrorIcon />,
    style: {
      background: '#fcf0f0',
      color: '#c23600',
      borderLeft: '5px solid #ff1b1b',
      borderRadius: '5px',
      alignItems: 'center',
      minWidth: '550px',
      padding: '6px 26px',
    },
    // duration: 0,
  });
};

// finding the pricing table inside the deliverable data structure of a Proposal
const findTableInProp = (prop) => {
  var tables = [];

  _.each(prop.draft, (section, sectionKey) => {
    if (
      section &&
      section.raw &&
      section.raw.entityMap &&
      prop.draft.sectionorder.indexOf(sectionKey) > -1
    ) {
      _.each(section.raw.entityMap, (v, k) => {
        if (v.data && v.data.texcontent) {
          try {
            var table = JSON.parse(v.data.texcontent);
            if (table && table.deliverables && table.pricing.strategy === 'table') {
              tables.push(table);
            }
          } catch (exx) {}
        }
      });
    }
  });

  return tables.length === 1 && tables[0]; // only if exactly one table
};

// finding the pricing items list inside the deliverable data structure of a Proposal
const findListInTable = (thetable) => {
  var thelist;
  if (!thetable || !thetable.deliverables || !thetable.deliverables.chosen) {
    return;
  }

  taxdisfix(thetable.deliverables.chosen); // fix tax / discount for back compat

  _.each(thetable.deliverables.chosen, (v, k) => {
    if (v.list && (!thelist || v.list.length > thelist.list.length)) {
      thelist = v;
    }
  });

  return thelist;
};

// finding the currency inside the deliverable data structure of a Proposal
const findCurrInProp = (prop) => {
  var tables = [];
  _.each(prop.draft, (section, sectionKey) => {
    if (section && section.raw && section.raw.entityMap) {
      _.each(section.raw.entityMap, (v, k) => {
        if (v.data && v.data.texcontent && prop.draft.sectionorder.indexOf(sectionKey) > -1) {
          try {
            var table = JSON.parse(v.data.texcontent);
            if (table && table.curr) {
              tables.push(table);
            }
          } catch (exx) {}
        }
      });
    }
  });

  return tables.length > 0 && tables[0].curr; // only if exactly one table
};

// backwards compatibility function for when we only had a discount and not tax
// makes sure both are handled correctly
const taxdisfix = (chosen) => {
  _.each(chosen, (v, k) => {
    var tax;
    var dis;

    if (v.tax) {
      if (v.tax.type === -1) {
        if (!v.discount || v.discount.type === 1) {
          // so we switch
          dis = v.tax;
        }
        v.tax = null; // remove now because its really discount
      }
    }
    if (v.discount) {
      if (v.discount.type === 1) {
        // means it's reall tax
        if (!v.tax || v.tax.type === -1) {
          // so we switch
          tax = v.discount;
        }
        v.discount = null; // remove now because it's really tax
      }
    }

    if (dis) {
      v.discount = dis;
    }

    if (tax) {
      v.tax = tax;
    }
  });
};

const getMatches = (string, regex) => {
  let matches = [];
  let match;
  /* eslint-disable */
  while ((match = regex.exec(string))) {
    matches.push(match[0]);
  }
  /* eslint-enable */
  return matches;
};

// parsing and handling currencies (used in Tablist mainly)
const parseFloatCurr = (prop, x, curr = '$') => {
  curr = curr || '$';
  curr = curr.toLowerCase();
  if (!x) {
    return 0;
  }

  if (typeof x === 'number') {
    x = toStringCurr(prop, x, curr);
  }

  if (prop.priceSeperator === '.') {
    return parseFloat(x.replace(/\./gim, 'DOT').replace(/,/gim, '.').replace(/DOT/gim, ''));
  } else if (prop.priceSeperator === 'space') {
    return parseFloat(x.replace(' ', ''));
  } else {
    return parseFloat(x.replace(/,/gim, ''));
  }
};

const toStringCurr = (prop, x, curr = '$') => {
  curr = curr || '$';
  curr = curr.toLowerCase();
  if (!x) {
    return 0;
  }

  if (typeof x === 'string') {
    x = parseFloatCurr(prop, x);
  }

  const strx = x.toString();
  if (prop.priceSeperator === '.') {
    return strx.replace(/\./gim, ','); //replace decimal point to , for the currency
  } else {
    return strx;
  }
};

// pay attention to sep
const onlyNumSeperator = (prop, x, curr = '$', changeTotal) => {
  curr = curr || '$';
  curr = curr.toLowerCase();

  // decimal seperator (not thowsands) so the other way around
  const sep = curr && prop.priceSeperator === '.' ? ',' : '.';
  const sepforregex = sep === '.' ? '\\.' : sep;
  if (x) {
    const m = getMatches(x, new RegExp(_.escapeRegExp(`[\\d${sepforregex}]`), 'gim'));
    const v = m ? parseFloatCurr(prop, m.join(''), curr) : 0;

    if (x === sep) {
      return `0${sep}`;
    }
    if (x === '-') {
      return '-';
    }

    let ret = v;

    // there was a decimal point but we removed it (after parsefloat, for example '1.') - fix
    // ( 100% fix but for value pricing only)
    if (changeTotal && x.indexOf(sep) !== -1) {
      const bfr = getMatches(
        x.slice(0, x.indexOf(sep)),
        new RegExp(_.escapeRegExp(`[\\d${sepforregex}]`), 'gim')
      ).join('');
      const aft = x.slice(x.indexOf(sep)).replace(new RegExp(`${sepforregex}`, 'gim'), '');
      ret = bfr + sep + aft;
    }
    // there was a decimal point but we removed it (after parsefloat, for example '1.') - fix
    // (not 100% because it does not fix xx.00)
    else if (x.indexOf(sep) !== -1 && ('' + v).indexOf('.') === -1) {
      const bfr = x.slice(0, x.indexOf(sep));
      const aft = x.slice(x.indexOf(sep)).replace(new RegExp(`${sepforregex}`, 'gim'), '');
      ret = bfr + sep + aft;
    }
    if (x.indexOf('-') !== -1) {
      ret = '-' + ret;
    }
    return ret;
  } else {
    return 0;
  }
};

const ellipsisText = (str, type) => {
  var n =
    window.innerWidth < 850
      ? 15
      : window.innerWidth < 1050
      ? 18
      : window.innerWidth < 1150
      ? 20
      : window.innerWidth < 1250
      ? 25
      : 30;
  if (type === 'TOC') {
    n =
      window.innerWidth < 850
        ? 10
        : window.innerWidth < 1050
        ? 13
        : window.innerWidth < 1150
        ? 16
        : window.innerWidth < 1250
        ? 19
        : 16;
  }
  return str?.length > n ? str.substring(0, n) + '...' : str;
};

const removeLastValue = (a) => {
  a = a.toString();
  const b = a.substr(0, a.length - 1);
  return b;
};

const popLastValuePercentSymbol = (a, s) => {
  if (a > 100 && s === '%') {
    a = a.toString();
    const b = a.substr(0, a.length - 1);
    return b;
  }
  return a;
};

// Url for the s3 / cloudfront, this is where most images are stored
const S3Image = (path = '', name) => {
  return `${process.env.REACT_APP_CLOUD_FRONT_URL}${path}${name}`;
};

const getSentenceAndSplit = (num, str) => {
  var splittedStr = str.split(' '),
    arrayStr = [],
    stringStr = '';
  splittedStr.forEach((sentence, index) => {
    stringStr += sentence + ' ';
    if ((index + 1) % num === 0) {
      arrayStr.push(stringStr);
      stringStr = '';
    } else if (splittedStr.length === index + 1 && stringStr !== '') {
      arrayStr.push(stringStr);
      stringStr = '';
    }
  });
  return arrayStr;
};

const checkValidURL = (url) => {
  try {
    return url?.match(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g //eslint-disable-line
    );
  } catch (error) {
    return false;
  }
};

const checkNameInPassword = (name, parsedPassword) => {
  let pattern = [];
  name = name.replace(/\s+/, '');
  for (let i = 0, l = name.length - 3; i < l; i++) {
    pattern.push(`${name[i]}(?=${name.slice(i + 1, i + 4)})`);
  }
  return !new RegExp(pattern.join('|'), 'i').test(parsedPassword);
};

const sequentialPassword = (str, n) => {
  var seq = false;
  var result = [];
  const num = '0123456789';
  const abc = 'abcdefghijklmnopqrstuvqxyz';

  if (str.length < n) return false;
  for (var i = 0; i < str.length; i++) {
    if (i + n > str.length) break;
    var chunka = str.slice(i, i + n);
    var seqABC = abc.indexOf(chunka) > -1;
    var seq123 = num.indexOf(chunka) > -1;
    if (seq123 || seqABC) {
      seq = true;
      result.push(chunka);
    }
  }
  return seq;
};

const passwordStrength = (passwordValue, name) => {
  const passwordLength = passwordValue.length;
  const passwordLengthMetric = passwordLength >= 8 ? true : false;

  const poorRegExp = /[a-z]/;
  const weakRegExp = /(?=.*?[0-9])/;
  const strongRegExp = /(?=.*?[#?!@$%^&*-])/;
  const whitespaceRegExp = /^$|\s+/;

  const poorPassword = poorRegExp.test(passwordValue);
  const weakPassword = weakRegExp.test(passwordValue);
  const strongPassword = strongRegExp.test(passwordValue);
  const whiteSpace = whitespaceRegExp.test(passwordValue);
  const checkNamedPassword = checkNameInPassword(name, passwordValue);
  const checkSequentialPassword = sequentialPassword(passwordValue, 3);

  if (passwordValue === '') {
    return {
      weakPassword: true,
      poorPassword: false,
      strongPassword: false,
      errorMessage: 'Password cannot be empty',
    };
  } else {
    if ((!passwordLengthMetric && whiteSpace) || (passwordLengthMetric && whiteSpace)) {
      return {
        weakPassword: true,
        poorPassword: false,
        strongPassword: false,
        errorMessage: 'Whitespaces are not allowed',
      };
    }
    if (
      (!passwordLengthMetric && checkNamedPassword === false) ||
      (passwordLengthMetric && checkNamedPassword === false)
    ) {
      return {
        weakPassword: true,
        poorPassword: false,
        strongPassword: false,
        errorMessage: 'Your name cannot be used in the password',
      };
    }
    if (
      (!passwordLengthMetric && checkSequentialPassword) ||
      (passwordLengthMetric && checkSequentialPassword)
    ) {
      return {
        weakPassword: true,
        poorPassword: false,
        strongPassword: false,
        errorMessage: 'Sequential characters are not allowed (ex: 123, abc)',
      };
    }
    if (passwordLengthMetric && (poorPassword || weakPassword || strongPassword)) {
      return {
        weakPassword: false,
        poorPassword: true,
        strongPassword: false,
        errorMessage: 'The password is poor',
      };
    }
    if (passwordLengthMetric && poorPassword && (weakPassword || strongPassword)) {
      return {
        weakPassword: true,
        poorPassword: false,
        strongPassword: false,
        errorMessage: 'The password is weak',
      };
    }
    if (passwordLengthMetric && poorPassword && weakPassword && strongPassword) {
      return {
        weakPassword: true,
        poorPassword: false,
        strongPassword: false,
        errorMessage: 'The password is strong',
      };
    }
    return {
      weakPassword: true,
      poorPassword: false,
      strongPassword: false,
      errorMessage: 'The password should have minimum of 8 character length.',
    };
  }
};

const simplePasswordPolicy = (value) => {
  const reDigit = /(?=.*[\d\!\@\#\$\%\^\&\*\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\_\-])/; //eslint-disable-line
  const reCharacter = /(?=.*[a-zA-Z])/;

  if (value.length > 0 && value.trim().length === 0) {
    return 'Your password cannot just have spaces!';
  } else if (value.length > 0 && value.length < 8) {
    return 'Your password must be at least 8 characters long, please try again.';
  } else if (value.length > 0 && !reCharacter.test(value) && !reDigit.test(value)) {
    return 'At least 1 letter and 1 number/special character is required';
  } else if (value.length > 0 && !reCharacter.test(value)) {
    return 'At least 1 letter is required';
  } else if (value.length > 0 && !reDigit.test(value)) {
    return 'At least 1 number or special character is required';
  } else {
    return null;
  }
};

const getParameterByName = (name, url) => {
  name = name.replace(/[\\[\]]/g, '\\$&');
  var regex = new RegExp(_.escapeRegExp('[?&]' + name + '(=([^&#]*)|&|#|$)')),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

const getParameterByNameOldWay = (name, url) => {
  name = name.replace(/[\\[\]]/g, '\\$&');
  var regex = new RegExp(_.unescape('[?&]' + name + '(=([^&#]*)|&|#|$)')),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

const getFileExtension = (path) => {
  let fileBaseName = path.split(/[\\/]/).pop(),
    poss = fileBaseName.lastIndexOf('.');
  if (fileBaseName === '' || poss < 1) return '';
  return fileBaseName.slice(poss + 1);
};

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const splitCamelCase = (string) => {
  return string.replace(/([a-z0-9])([A-Z])/g, '$1 $2');
};

const splitAndCapitaliseFirstLetter = (string) => {
  let strings = string.replace(/([a-z0-9])([A-Z])/g, '$1 $2');
  return strings
    .split(' ')
    .map((s) => s.charAt(0).toUpperCase() + s.slice(1))
    .join(' ');
};

const changeVariable = (variables, html, dateFormat) => {
  function getValue(displayValue, variable) {
    if (dateFormat) {
      // transform date to user's format
      if (variable.includes('Date') && displayValue?.length > 8) {
        const date = moment(displayValue.split('-').reverse().join('-')).format(
          dateFormat || 'DD/MM/YYYY'
        );
        if (date !== 'Invalid date') {
          return date;
        }
      }
    }

    return displayValue;
  }

  if (variables && html) {
    Object.keys(variables).forEach((value) => {
      Object.keys(variables[value]).forEach((variable) => {
        const displayValue = variables[value][variable].value;
        html = !!displayValue
          ? html.split('{{' + value + '.' + variable + '}}').join(getValue(displayValue, variable))
          : html;
      });
    });
  }
  return html;
};

const refetchUserOnClosingTab = (url, tab, dontOpenNewTab) => {
  return new Promise((resolve) => {
    let win;
    if (dontOpenNewTab) {
      win = window.open(url, '_self', tab);
    } else {
      win = window.open(url, '_blank', tab);
    }
    const timer = setInterval(function () {
      if (win.closed) {
        clearInterval(timer);
        resolve();
      }
    }, 1000);
  });
};

const checkNan = (val, isDot) => {
  if (val) {
    val = String(val);
    if (isDot) {
      val = val.replaceAll(',', '.');
      return isNaN(val);
    } else {
      return isNaN(val);
    }
  } else {
    return false;
  }
};

const getTitleText = (proposal, sectionName, spaceis = '_', sectionIndex) => {
  try {
    const companyName = proposal.client ? proposal.client.name : 'Lonely Pizza';

    // handle title text since it can be "html" from the header editor field (can be raw draftjs)
    const section = proposal.draft && proposal.draft[sectionName];
    let titleHTML = (proposal.project && proposal.project.name) || `The ${companyName} Project`;
    let titleText = titleHTML;

    if (section?.rawtitle) {
      const titlecs = convertFromRaw(section.rawtitle);
      titleHTML = stateToHTML(titlecs);
      titleText = titleHTML;
      try {
        /* eslint-disable */
        titleText = titleHTML
          .replace(/\<br>/gim, '_')
          .replace(/\s/gim, spaceis)
          .replace(/\<.*?\>/gim, '')
          .replace(/&nbsp;/g, ' ');
        /* eslint-enable */
      } catch (e) {
        return proposal._id;
      }
    } else if (section?.title) {
      titleText = section.title;
    } else if (section?.image) {
      titleText = `Full-Width Image ${sectionIndex || 0}`;
    }

    return titleText;
  } catch (ex) {
    return proposal._id;
  }
};

const updateClarity = ({ _id, name, email, createdAt, loggedInAt }) => {
  if (process.env.REACT_APP_ENV === 'production' && window.clarity) {
    window.clarity('identify', email);
    window.clarity('set', '_id', _id);
    window.clarity('set', 'displayName', name);
    window.clarity('set', 'email', email);
    if (loggedInAt) {
      window.clarity('set', 'loggedInAt', loggedInAt);
    }
    if (createdAt) {
      window.clarity('set', 'createdAt', createdAt);
    }
  }
};

const limitNumber = (v, max = 9999999999) => {
  if (!v) return 0;
  if (typeof v === 'string' || (typeof v === 'number' && v > max)) {
    return Number(v.toString().substring(0, max.toString().length));
  }
  return Number(v);
};

const onlyNumber = (value, keepDecimal = true, max = 9999999999) => {
  if (typeof value === 'number') {
    return Math.min(value, max);
  }

  if (typeof value === 'string') {
    if (keepDecimal) {
      // remove all non numeric characters except .
      // cut string length to 10
      value = value.replace(/[^0-9.]/g, '').substring(0, max.toString().length);

      // keep only 1 decimal point
      const valueArr = value.split('.');
      if (valueArr.length > 2) {
        return `${valueArr[0]}.${valueArr.slice(1).join('')}`;
      }
    } else {
      // remove all non numeric characters
      return value.replace(/[^0-9]/g, '').substring(0, max.toString().length);
    }

    return value;
  }

  return 0;
};

const transformPopoverText = (totalText, totalValue, configText, language) => {
  if (totalValue !== '') {
    if (language === 'hebrew' || language === 'arabic') {
      return `(${configText(totalValue)}) ${totalText}`;
    } else {
      return `${totalText} (${configText(totalValue)})`;
    }
  }

  return totalText;
};

const verifyMondayAccount = (userMondayAccountId, currentMondayAccountToken) => {
  if (!userMondayAccountId) {
    return false;
  }
  const decodedMondayBody = JSON.parse(
    Buffer.from(currentMondayAccountToken.split('.')[1], 'base64').toString()
  );
  return String(userMondayAccountId) !== String(decodedMondayBody?.dat?.account_id);
};

const getSignText = (text, isChanged, language = 'english') => {
  const maps = {
    hebrew: ['ההצעה', 'המסמך'],
    english: ['Proposal', 'Document'],
    englishSmall: ['proposal', 'document'],
  };
  if (!maps[language]) {
    language = 'english';
  }

  // if default btn text is changed then replace Proposal with Document
  return isChanged
    ? text
        .replace(maps[language][0], maps[language][1])
        .replace(maps.englishSmall[0], maps.englishSmall[1])
    : text;
};

const evaluateExpression = (expression) => {
  if (typeof expression === 'number') {
    return expression;
  }
  const operators = expression.match(/[+\-*/]/g) || [];
  const numbers = expression.split(/[+\-*/]/).map(Number);

  let [result] = numbers;

  for (let i = 0; i < operators.length; i++) {
    const operator = operators[i];
    const operand = numbers[i + 1];

    switch (operator) {
      case '+':
        result += operand;
        break;
      case '-':
        result -= operand;
        break;
      case '*':
        result *= operand;
        break;
      case '/':
        result /= operand;
        break;
      default:
        break;
    }
  }

  return result;
};

const replaceHttp = (url) => {
  if (!url || !url?.length) {
    return '';
  }

  return url.replace('http://', 'https://');
};

/*
based on background color, return the contrast color
for light background, return dark color
for dark background, return light color
*/
const getContrastColor = (bgColor, lightColor = '#ffffff', darkColor = '#000000') => {
  let r,
    b,
    g = 0;

  if (bgColor.charAt(0) === '#' && bgColor.length === 7) {
    r = hexToNum(bgColor.substring(1, 3));
    g = hexToNum(bgColor.substring(3, 5));
    b = hexToNum(bgColor.substring(5, 7));
  } else if (bgColor.charAt(0) === 'rgb') {
    bgColor = bgColor.replace(/[^0-9,]/g, '').split(',');
    if (bgColor.length === 3) {
      r = parseInt(bgColor[0]);
      g = parseInt(bgColor[1]);
      b = parseInt(bgColor[2]);
    }
  } else {
    return darkColor;
  }

  return r * 0.299 + g * 0.587 + b * 0.114 > 150 ? darkColor : lightColor;

  function hexToNum(hex) {
    return parseInt(hex, 16);
  }
};

const isLink = (str) => {
  // to find wheather a string is link or not
  const urlRegex = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  );
  return !!urlRegex.test(str);
};

const cutLongText = (str, maxLength = 50) => {
  if (!str || str.length < maxLength) {
    return str;
  }
  return `${str.substring(0, maxLength / 2)}...${str.substring(str.length - maxLength / 2)}`;
};

/*
based on title and body width of a section
check if pricing element is dropable
*/
const calculatePricingDropable = (titleSpan, bodySpan) => {
  /*
  allow pricing dropable for below cases
  for side normal title (span=8) body span=16
  for side small title (span=4) body span=20
  for full width title (span=24) body span>=18
  */
  if (
    (titleSpan === 8 && bodySpan === 16) ||
    (titleSpan === 4 && bodySpan === 20) ||
    (titleSpan === 24 && bodySpan >= 18)
  ) {
    return true;
  }

  return false;
};

const isRTLProposal = (proposal) => {
  return RTL_LANGUAGES.includes(proposal?.language?.toLowerCase());
};

const utils = {
  dataURItoBlob,
  isSafari,
  getURLParam,
  createCookie,
  readCookie,
  deleteCookie,
  loginredirect,
  getQueryStringValue,
  addListenerMulti,
  cacheGeoData,
  showErrorMessage,
  findTableInProp,
  findListInTable,
  taxdisfix,
  findCurrInProp,
  onlyNumSeperator,
  ellipsisText,
  removeLastValue,
  popLastValuePercentSymbol,
  S3Image,
  getSentenceAndSplit,
  checkValidURL,
  passwordStrength,
  simplePasswordPolicy,
  getParameterByName,
  getFileExtension,
  capitalizeFirstLetter,
  splitCamelCase,
  splitAndCapitaliseFirstLetter,
  changeVariable,
  refetchUserOnClosingTab,
  checkNan,
  getTitleText,
  updateClarity,
  limitNumber,
  onlyNumber,
  transformPopoverText,
  verifyMondayAccount,
  getSignText,
  getContrastColor,
  evaluateExpression,
  replaceHttp,
  isLink,
  cutLongText,
  calculatePricingDropable,
  getParameterByNameOldWay,
  isRTLProposal,
};

export default utils;
