import React, { useRef, useState } from 'react';
import { Col, Row, Divider, Popover, Button, Tooltip } from 'antd';
import { SketchPicker } from 'react-color';
import PropTypes from 'prop-types';

import DeleteIcon from 'components/Icons/DeleteIcon';
import RoundDragIcon from 'components/Icons/RoundDragIcon';
import AddColumnIcon from 'components/Icons/AddColumnIcon';
import AddRowIcon from 'components/Icons/AddRowIcon';
import ResizeIcon from 'components/Icons/ResizeIcon';
import LeftAlignIcon from 'components/Icons/LeftAlignIcon';
import CenterAlignIcon from 'components/Icons/CenterAlignIcon';
import RightAlignIcon from 'components/Icons/RightAlignIcon';
import ShadowPicker from 'components/ShadowPicker/ShadowPicker';
import BorderPicker from 'components/BorderPicker/BorderPicker';

import '../index.scss';
import { HyperLinkIcon } from 'components/Icons';

const ComponentWrapper = ({
  sectionName,
  setDraggingElement,
  children,
  blockKey,
  showDrag,
  onFocus,
  onBlur,
  showActionButtons,
  duplicate,
  remove,
  addRow,
  addColumn,
  onColorChange,
  onEdit,
  onDragMouseDown,
  showDuplicate,
  showEdit,
  showDelete,
  isDraggable,
  handleColumn,
  config,
  handleEditComponent,
  showTableActions,
  showTestimonialActions,
  componentType,
  showSave,
  onSave,
  onCancel,
  showImageActions,
  onShadowChange,
  shadow,
  saveShadow,
  border,
  saveBorder,
  onBorderChange,
  handleImageAlignment,
  isSaving,
  componentWrapperStyle,
  setDropDisabled,
  onReplace,
  hyperlink,
  onHyperLinkSave,
  dispatch,
  toolbarClassName,
  toolbar,
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const [deleteVisible, setDeleteVisible] = useState(false);
  const [shadowPickerVisibility, setShadowPickerVisibility] = useState(false);
  const [borderPickerVisibility, setBorderPickerVisibility] = useState(false);
  const componentWrapperRef = useRef();

  const handleMouseLeave = () => {
    if (onBlur && !showImageActions) {
      onBlur();
    }
  };

  const handleDrag = (event, type) => {
    if (event.target.className.match('rich-editor-components-wrapper')) {
      if (type === 'start') {
        const img = new Image();
        event.dataTransfer.setDragImage(img, 100, 100);

        event.dataTransfer.dropEffect = 'move';
        event.dataTransfer.effectAllowed = 'all';
        event.dataTransfer.setData('text', blockKey);

        // Can be used in future to move elements between sections
        // event.dataTransfer.setData('sectionName', sectionName);

        handleEditComponent(false, 'drag');
        setIsDragging(true);
        setDropDisabled(sectionName);
        setDraggingElement('start');
      } else {
        handleEditComponent(true, 'drag');
        setIsDragging(false);
        setDropDisabled('');
        setDraggingElement('end');
      }
    }
  };

  const handleDeleteVisibleChange = (visible) => {
    setDeleteVisible(visible);
    if (onBlur) {
      onBlur();
    }
  };

  const onHyperLinkClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const actionButtonBound = event.target
      .closest('.rich-editor-components-action-buttons')
      .getBoundingClientRect();
    const sectionBound = event.target.closest('.simple-section-content').getBoundingClientRect();

    let x = actionButtonBound.left - sectionBound.left;
    let y = actionButtonBound.top - sectionBound.top + 40;
    const inputWidth = actionButtonBound.left + 468;

    if (actionButtonBound.bottom + 80 > window.innerHeight) {
      y -= 120;
    }

    if (inputWidth >= sectionBound.right) {
      x -= inputWidth - sectionBound.right;
    }

    dispatch({
      type: 'setDisplayHyperlinkInput',
      displayHyperlinkInput: {
        position: {
          left: `${x}px`,
          top: `${y}px`,
        },
        link: hyperlink,
        onSubmit: onHyperLinkSave,
      },
    });
  };

  const setActionButtonsPosition = () => {
    const componentWrapperBound = componentWrapperRef.current?.getBoundingClientRect();
    const sectionBound = componentWrapperRef.current
      ?.closest('.section-wrapper')
      .getBoundingClientRect();

    if (componentWrapperBound && sectionBound) {
      const toolbarRight = componentWrapperBound.left + 240;

      if (toolbarRight + 30 >= sectionBound.right) {
        const left = toolbarRight - sectionBound.right + 15;
        return {
          left: `-${left}px`,
          minWidth: '240px',
        };
      }

      const toolbarLeft = componentWrapperBound.right - 240;

      if (toolbarLeft - 30 <= sectionBound.left) {
        const right = toolbarLeft - sectionBound.left - 15;
        return {
          right: `${right}px`,
          minWidth: '240px',
        };
      }
    }
    return {
      minWidth: '240px',
    };
  };

  return (
    <div
      ref={componentWrapperRef}
      className={`rich-editor-components-wrapper 
      ${showSave ? 'show-save' : ''} ${componentType?.toLowerCase()}-wrapper
      ${config?.alignment ? 'rich-editor-components-wrapper-' + config.alignment : ''}`}
      onClick={onFocus}
      onBlur={onBlur ? () => onBlur() : null}
      onMouseLeave={onBlur ? () => handleMouseLeave() : null}
      onDragStart={showDrag ? (event) => handleDrag(event, 'start') : null}
      onDragEnd={showDrag ? (event) => handleDrag(event, 'end') : null}
      draggable={isDraggable}
      style={componentWrapperStyle}>
      {showDrag && (
        <Tooltip title="Move">
          <RoundDragIcon
            className={`rich-editor-components-draggable-icon ${
              isSaving ? 'rich-editor-components-action-disabled' : ''
            }`}
            id="rich-editor-components-draggable-icon"
            style={isDragging ? { display: 'flex' } : {}}
          />
        </Tooltip>
      )}
      {showActionButtons && !isDragging && (
        <div
          className={`rich-editor-components-action-buttons ${toolbarClassName}`}
          style={showImageActions ? setActionButtonsPosition() : null}>
          {toolbar || null}

          <Col>
            {showEdit && onEdit && <span onClick={onEdit}>Edit</span>}
            {showImageActions && showEdit && <span onClick={onReplace}>Replace</span>}
            {showSave && (
              <>
                <span
                  className={isSaving ? 'rich-editor-components-action-disabled' : ''}
                  onClick={onSave}>
                  Save
                </span>
                <span
                  className={isSaving ? 'rich-editor-components-action-disabled' : ''}
                  onClick={onCancel}>
                  Cancel
                </span>
              </>
            )}

            {showDuplicate && <span onClick={duplicate}>Duplicate</span>}
            {showImageActions && showEdit && (
              <span className={isSaving ? 'rich-editor-components-action-disabled' : ''}>
                <Tooltip title="Hyperlink" onClick={onHyperLinkClick}>
                  <HyperLinkIcon />
                </Tooltip>
              </span>
            )}
            {showDelete && (
              <Popover
                content={
                  <Col className="table-delete-popover">
                    <h3 className="ant-popover-title">Delete {componentType}?</h3>
                    <Divider />
                    <Row>
                      <Button
                        size="middle"
                        type="button"
                        className="Prosprich-editor-components-popover-confirm"
                        onClick={remove}>
                        Delete
                      </Button>
                      <Button
                        size="middle"
                        type="button"
                        className="Prosprich-editor-components-popover-cancel"
                        onClick={() => handleDeleteVisibleChange(false)}>
                        Cancel
                      </Button>
                    </Row>
                  </Col>
                }
                placement={showTestimonialActions ? 'bottomRight' : 'bottomLeft'}
                overlayClassName="Prosprich-editor-components-popover"
                trigger="click"
                visible={deleteVisible}
                onVisibleChange={handleDeleteVisibleChange}>
                <Tooltip title="Delete">
                  <span className={isSaving ? 'rich-editor-components-action-disabled' : ''}>
                    <DeleteIcon className="delete-icon" />
                  </span>
                </Tooltip>
              </Popover>
            )}
          </Col>

          {showTableActions && (
            <>
              <Col>
                <span onClick={addColumn}>
                  <Tooltip title="Add Column">
                    <AddColumnIcon className="column-icon" />
                  </Tooltip>
                </span>
                <span onClick={addRow}>
                  <Tooltip title="Add Row">
                    <AddRowIcon className="row-icon" />
                  </Tooltip>
                </span>
              </Col>
              <Col>
                <Popover
                  placement="bottom"
                  trigger="click"
                  content={
                    <SketchPicker
                      color={config.topRowColor}
                      onChange={(color) =>
                        onColorChange({ color, topRowSketch: true, rowSketch: false })
                      }
                    />
                  }>
                  Top Row{' '}
                  <div className="color-circle" style={{ backgroundColor: config.topRowColor }} />
                </Popover>

                <Popover
                  placement="bottom"
                  trigger="click"
                  content={
                    <SketchPicker
                      color={config.rowColor}
                      onChange={(color) =>
                        onColorChange({ color, topRowSketch: false, rowSketch: true })
                      }
                    />
                  }>
                  Row <div className="color-circle" style={{ backgroundColor: config.rowColor }} />
                </Popover>
              </Col>
            </>
          )}
          {config && config?.columns && (
            <Col>
              <span onClick={() => handleColumn(config?.columns === 1 ? 2 : 1)}>
                {' '}
                {config?.columns === 1 ? `2 Columns` : `1 Column`}{' '}
              </span>
            </Col>
          )}
          {showImageActions && !showSave && (
            <Col>
              <Popover
                content={
                  <ShadowPicker
                    shadow={shadow}
                    onChange={(value) => onShadowChange(value)}
                    save={(value) => {
                      setShadowPickerVisibility(false);
                      saveShadow(value);
                    }}
                    cancel={() => setShadowPickerVisibility(false)}
                  />
                }
                placement="bottomLeft"
                overlayClassName="Prosprich-editor-components-popover"
                trigger="click"
                visible={shadowPickerVisibility}
                onVisibleChange={setShadowPickerVisibility}>
                <span>Shadow</span>
              </Popover>
              <Popover
                content={
                  <BorderPicker
                    border={border}
                    onChange={(value) => onBorderChange(value)}
                    save={(value) => {
                      setBorderPickerVisibility(false);
                      saveBorder(value);
                    }}
                    cancel={() => setBorderPickerVisibility(false)}
                  />
                }
                placement="bottomLeft"
                overlayClassName="Prosprich-editor-components-popover"
                trigger="click"
                visible={borderPickerVisibility}
                onVisibleChange={setBorderPickerVisibility}>
                <span>Border</span>
              </Popover>

              <Tooltip title="Left Align">
                <LeftAlignIcon
                  className={config?.alignment === 'left' ? 'anticon-active' : ''}
                  onClick={() => handleImageAlignment('left')}
                />
              </Tooltip>
              <Tooltip title="Center Align">
                <CenterAlignIcon
                  className={config?.alignment === 'center' ? 'anticon-active' : ''}
                  onClick={() => handleImageAlignment('center')}
                />
              </Tooltip>
              <Tooltip title="Right Align">
                <RightAlignIcon
                  className={config?.alignment === 'right' ? 'anticon-active' : ''}
                  onClick={() => handleImageAlignment('right')}
                />
              </Tooltip>
            </Col>
          )}
        </div>
      )}
      {showDrag && (
        <>{!isDragging ? children : <Divider className="rich-editor-components-dragger" />}</>
      )}
      {showTableActions && (
        <>
          {showActionButtons && !isDragging && (
            <div className="table-resize-container">
              <Tooltip title="Resize" placement="bottom">
                <ResizeIcon id="table-drag-icon" onMouseDown={onDragMouseDown} />
              </Tooltip>
            </div>
          )}
        </>
      )}
      {!showDrag && children}
    </div>
  );
};

ComponentWrapper.propTypes = {
  children: PropTypes.element.isRequired,
  blockKey: PropTypes.string.isRequired,
  showDrag: PropTypes.bool,
  showDuplicate: PropTypes.bool,
  showEdit: PropTypes.bool,
  showDelete: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  showActionButtons: PropTypes.bool,
  showTestimonialActions: PropTypes.bool,
  showTableActions: PropTypes.bool,
  duplicate: PropTypes.func,
  remove: PropTypes.func,
  addRow: PropTypes.func,
  addColumn: PropTypes.func,
  onColorChange: PropTypes.func,
  onEdit: PropTypes.func,
  onReplace: PropTypes.func,
  onHyperLinkSave: PropTypes.func,
  hyperlink: PropTypes.string,
  handleColumn: PropTypes.func,
  handleEditComponent: PropTypes.func,
  isDraggable: PropTypes.bool,
  config: PropTypes.instanceOf(Object),
  showSave: PropTypes.bool,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  showImageActions: PropTypes.bool,
  onShadowChange: PropTypes.func,
  shadow: PropTypes.instanceOf(Object),
  saveShadow: PropTypes.func,
  border: PropTypes.instanceOf(Object),
  saveBorder: PropTypes.func,
  onBorderChange: PropTypes.func,
  isSaving: PropTypes.bool,
  handleImageAlignment: PropTypes.func,
  componentWrapperStyle: PropTypes.instanceOf(Object),
  setDropDisabled: PropTypes.func,
  toolbarClassName: PropTypes.string,
};

export default ComponentWrapper;
