// possible languages
const languages = [
  {
    text: 'English',
    value: 'english',
  },
  {
    text: 'Hebrew',
    value: 'hebrew',
  },
  {
    text: 'German',
    value: 'german',
  },
  {
    text: 'Portuguese (BR)',
    value: 'portuguese (BR)',
  },
  {
    text: 'Portuguese (PT)',
    value: 'portuguese (PT)',
  },
  {
    text: 'Spanish',
    value: 'spanish',
  },
  {
    text: 'Italian',
    value: 'italian',
  },
  {
    text: 'Polish',
    value: 'polish',
  },
  {
    text: 'French',
    value: 'french',
  },
  {
    text: 'Dutch',
    value: 'dutch',
  },
  {
    text: 'Russian',
    value: 'russian',
  },
  {
    text: 'Turkish',
    value: 'turkish',
  },
  {
    text: 'Romanian',
    value: 'romanian',
  },
  {
    text: 'Norwegian',
    value: 'norwegian',
  },
  {
    text: 'Japanese',
    value: 'japanese',
  },
];

export const RTL_LANGUAGES = ['hebrew', 'arabic'];

const Languages = languages.sort((a, b) => a.text.localeCompare(b.value));

export default Languages;
