import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Row, Col, Card, Tooltip } from 'antd';
import { DragOutlined } from '@ant-design/icons';

import TextIcon from 'components/Icons/TextIcon';

import DividerIcon from 'components/Icons/DividerIcon';
import QuoteIcon from 'components/Icons/QuoteIcon';
import VideoIcon from 'components/Icons/VideoIcon';
import GalleryIcon from 'components/Icons/GalleryIcon';
import ImageIcon from 'components/Icons/ImageIcon';
import TableIcon from 'components/Icons/TableIcon';
import PriceTableIcon from 'components/Icons/PriceTableIcon';
import GoalTableIcon from 'components/Icons/GoalTableIcon';
import FormIcon from 'components/Icons/FormIcon';
import AddFile from 'components/Icons/AddFile';
import HTMLIcon from 'components/Icons/HTMLIcon';
import VariablesIcon from 'components/Icons/VariablesIcon';
import TestimonialIcon from 'components/Icons/TestimonialIcon';
import TitleDivider from '../../TitleDivider/TitleDivider';

import '../ElementsMenu.scss';

const ElementsView = ({
  handleToolbarElements,
  dragStart,
  dragEnd,
  showAttachmentModal,
  showVariables,
  setShowVariables,
  setScrollingTo,
  wixEditor,
  templateWixEditor,
}) => {
  const [showElementsView, setShowElementsView] = useState(false);

  useEffect(() => {
    setShowElementsView(!showVariables);
  }, [showVariables]);

  return showElementsView ? (
    <div className="elements-menu">
      <TitleDivider className="divider" content="Typography" />
      <Row gutter={[24, 24]} className="items">
        <Col span={12} id="add-text" onDragStart={dragStart} onDragEnd={dragEnd} draggable>
          <Tooltip
            title="Insert Text"
            className="elements-menu-drag"
            overlayClassName="elements-menu-tooltip">
            <DragOutlined />
          </Tooltip>
          <Card onMouseDown={(e) => handleToolbarElements(e, 'text')}>
            <TextIcon className="icon" />
            <span className="spacer" />
            <span className="text">Text</span>
          </Card>
        </Col>

        <Col span={12} id="add-divider" onDragStart={dragStart} onDragEnd={dragEnd} draggable>
          <Tooltip
            title="Insert Divider"
            className="elements-menu-drag"
            overlayClassName="elements-menu-tooltip">
            <DragOutlined />
          </Tooltip>
          <Card onMouseDown={(e) => handleToolbarElements(e, 'divider')}>
            <DividerIcon className="icon" />
            <span className="spacer" />
            <span className="text">Divider</span>
          </Card>
        </Col>

        <Col span={12} id="add-qoute" onDragStart={dragStart} onDragEnd={dragEnd} draggable>
          <Tooltip
            title="Insert BlockQuotes"
            className="elements-menu-drag"
            overlayClassName="elements-menu-tooltip">
            <DragOutlined />
          </Tooltip>
          <Card onMouseDown={(e) => handleToolbarElements(e, 'blockquote')}>
            <QuoteIcon className="icon" />
            <span className="spacer" />
            <span className="text">Quote</span>
          </Card>
        </Col>

        <Col span={12} id="add-form" onDragStart={dragStart} onDragEnd={dragEnd} draggable>
          <Tooltip
            title="Insert Form"
            className="elements-menu-drag"
            overlayClassName="elements-menu-tooltip">
            <DragOutlined />
          </Tooltip>
          <Card onMouseDown={(e) => handleToolbarElements(e, 'form')}>
            <FormIcon className="icon" />
            <span className="spacer" />
            <span className="text">Form</span>
          </Card>
        </Col>
      </Row>

      <TitleDivider className="divider" content="Media" />
      <Row gutter={[24, 24]} className="items">
        <Col span={12} id="add-image" onDragStart={dragStart} onDragEnd={dragEnd} draggable>
          <Tooltip
            title="Insert Image"
            className="elements-menu-drag"
            overlayClassName="elements-menu-tooltip">
            <DragOutlined />
          </Tooltip>
          <Card
            onMouseDown={(e) => {
              e.stopPropagation();
              handleToolbarElements(e, 'image');
            }}>
            <ImageIcon className="icon" />
            <span className="spacer" />
            <span className="text">Image</span>
          </Card>
        </Col>

        <Col span={12} id="add-gallery" onDragStart={dragStart} onDragEnd={dragEnd} draggable>
          <Tooltip
            title="Insert Gallery"
            className="elements-menu-drag"
            overlayClassName="elements-menu-tooltip">
            <DragOutlined />
          </Tooltip>
          <Card onMouseDown={(e) => handleToolbarElements(e, 'gallery')}>
            <GalleryIcon className="icon" />
            <span className="spacer" />
            <span className="text">Gallery</span>
          </Card>
        </Col>

        <Col span={12} id="add-video" onDragStart={dragStart} onDragEnd={dragEnd} draggable>
          <Tooltip
            title="Insert Video"
            className="elements-menu-drag"
            overlayClassName="elements-menu-tooltip">
            <DragOutlined />
          </Tooltip>
          <Card onMouseDown={(e) => handleToolbarElements(e, 'video')}>
            <VideoIcon className="icon" />
            <span className="spacer" />
            <span className="text">Video</span>
          </Card>
        </Col>

        <Col span={12} id="add-attachment" onDragStart={dragStart} onDragEnd={dragEnd} draggable>
          <Tooltip
            title="Insert Attachment"
            className="elements-menu-drag"
            overlayClassName="elements-menu-tooltip">
            <DragOutlined />
          </Tooltip>
          <Card onClick={() => showAttachmentModal?.(true)}>
            <AddFile
              className="icon"
              onClick={(e) => e.stopPropagation()}
              onMouseDown={(e) => handleToolbarElements(e, 'attachment')}
            />
            <span className="spacer" />
            <span className="text">Attach File</span>
          </Card>
        </Col>
      </Row>

      <TitleDivider className="divider" content="More Elements" />
      <Row gutter={[24, 24]} className="items">
        <Col span={12} id="add-table" onDragStart={dragStart} onDragEnd={dragEnd} draggable>
          <Tooltip
            title="Insert Table"
            className="elements-menu-drag"
            overlayClassName="elements-menu-tooltip">
            <DragOutlined />
          </Tooltip>
          <Card onMouseDown={(e) => handleToolbarElements(e, 'table')}>
            <TableIcon className="icon" />
            <span className="spacer" />
            <span className="text">Table</span>
          </Card>
        </Col>

        {!wixEditor && !templateWixEditor && (
          <Col span={12} id="add-price" onDragStart={dragStart} onDragEnd={dragEnd} draggable>
            <Tooltip
              title="Insert Price Module"
              className="elements-menu-drag"
              overlayClassName="elements-menu-tooltip">
              <DragOutlined />
            </Tooltip>
            <Card onMouseDown={(e) => handleToolbarElements(e, 'price')}>
              <PriceTableIcon className="icon" />
              <span className="spacer" />
              <span className="text">Price Module</span>
            </Card>
          </Col>
        )}

        <Col span={12} id="add-html" onDragStart={dragStart} onDragEnd={dragEnd} draggable>
          <Tooltip
            title="Insert HTML"
            className="elements-menu-drag"
            overlayClassName="elements-menu-tooltip">
            <DragOutlined />
          </Tooltip>
          <Card onMouseDown={(e) => handleToolbarElements(e, 'html')}>
            <HTMLIcon className="icon" />
            <span className="spacer" />
            <span className="text">HTML</span>
          </Card>
        </Col>

        {!wixEditor && !templateWixEditor && (
          <Col span={12} id="add-goals" onDragStart={dragStart} onDragEnd={dragEnd} draggable>
            <Tooltip
              title="Insert Goals Module"
              className="elements-menu-drag"
              overlayClassName="elements-menu-tooltip">
              <DragOutlined />
            </Tooltip>
            <Card onMouseDown={(e) => handleToolbarElements(e, 'miles')}>
              <GoalTableIcon className="icon" />
              <span className="spacer" />
              <span className="text">Goal Module</span>
            </Card>
          </Col>
        )}

        <Col span={12} id="add-variable" onDragStart={dragStart} onDragEnd={dragEnd} draggable>
          <Card
            onMouseDown={(e) => {
              setShowVariables(true);
              setScrollingTo('client');
            }}>
            <VariablesIcon className="icon" />
            <span className="spacer" />
            <span className="text">Variables</span>
          </Card>
        </Col>

        <Col span={12} id="add-testimonial" onDragStart={dragStart} onDragEnd={dragEnd} draggable>
          <Tooltip
            title="Insert testimonial"
            className="elements-menu-drag"
            overlayClassName="elements-menu-tooltip">
            <DragOutlined />
          </Tooltip>
          <Card onMouseDown={(e) => handleToolbarElements(e, 'testimonial')}>
            <TestimonialIcon className="icon" />
            <span className="spacer" />
            <span className="text">Testimonial</span>
          </Card>
        </Col>
      </Row>
    </div>
  ) : (
    ''
  );
};

ElementsView.propTypes = {
  handleToolbarElements: PropTypes.func.isRequired,
  dragStart: PropTypes.func.isRequired,
  dragEnd: PropTypes.func.isRequired,
  showAttachmentModal: PropTypes.func.isRequired,
  wixEditor: PropTypes.bool,
  templateWixEditor: PropTypes.bool,
};

export default ElementsView;
