import React, { useState, useEffect } from 'react';
import { Button, Row, Col, Card, Tooltip, Input, Form } from 'antd';
import PropTypes from 'prop-types';

import SectionAddIcon from 'components/Icons/SectionAddIcon';
import DeleteIcon2 from 'components/Icons/DeleteIcon2';
import ArrowUpDisabled from 'components/Icons/ArrowUpDisabled';
import ArrowUpEnabled from 'components/Icons/ArrowUpEnabled';
import ArrowDownEnabled from 'components/Icons/ArrowDownEnabled';
import ArrowDownDisabled from 'components/Icons/ArrowDownDisabled';
import ReOrderIcon from 'components/Icons/ReOrderIcon';
import CloseIcon from 'components/Icons/CloseIcon';
import FullWidthImageIcon from 'components/Icons/FullWidthImageIcon';
import ImportSectionIcon from 'components/Icons/ImportSectionIcon';
import AddFromLibraryIcon from 'components/Icons/AddFromLibraryIcon';
import NewSectionIcon from 'components/Icons/NewSectionIcon';
import ColumnsOneIcon from 'components/Icons/ColumnsOneIcon';
import ColumnsTwoIcon from 'components/Icons/ColumnsTwoIcon';
import ColumnsThreeIcon from 'components/Icons/ColumnsThreeIcon';
import ColumnsFourIcon from 'components/Icons/ColumnsFourIcon';
import ColumnsTwoLeftIcon from 'components/Icons/ColumnsTwoLeftIcon';
import ColumnsTwoRightIcon from 'components/Icons/ColumnsTwoRightIcon';
import DropdownIcon from 'components/Icons/DropdownIcon';

import {
  CircleTickIcon,
  TitleNoTitle,
  TitleSideNormal,
  TitleSideSmall,
  TitleTopCenter,
  TitleTopLeft,
} from 'components/Icons';

import helpers from 'helpers/proposal';
import ContentLibraryTree from 'pages/ContentLibrary/components/ContentLibraryTree/ContentLibraryTree';

import './SectionWrapper.scss';

const NewSection = ({ onClick, wixEditor, templateWixEditor, sectionDroppable }) => (
  <div
    className="new-section"
    onDrop={(event) => {
      event.preventDefault();
      const section = JSON.parse(event.dataTransfer.getData('section'));
      onClick({ actionType: 'addNewSectionFromLibrary', section });
    }}
    onDragOver={(event) => event.preventDefault()}>
    <div className="wrapper">
      {sectionDroppable ? (
        <NewSectionDrop
          className="new-section-drop"
          onClick={() => null}
          text="Drop your saved section here"
        />
      ) : (
        <>
          <h4>What Do You Want To Add?</h4>

          {!wixEditor && !templateWixEditor && (
            <Row className="justify-content-center" gutter={16}>
              {[
                {
                  icon: <ImportSectionIcon />,
                  text: 'Import Section',
                  actionType: 'importSection',
                  type: 'importSection',
                },
                {
                  icon: <AddFromLibraryIcon />,
                  text: 'Add From Library',
                  actionType: 'addFromLibrary',
                  type: 'addFromLibrary',
                },
              ].map(({ type, actionType, ...rest }, i) => (
                <AddNewSectionCard
                  key={i}
                  {...rest}
                  onClick={() => onClick({ type, actionType })}
                />
              ))}
            </Row>
          )}

          <h5>Or select new section layout</h5>
          <hr />
          <Row gutter={16}>
            {[
              {
                icon: <TitleSideNormal />,
                text: 'Side normal',
                actionType: 'addNewSection',
                titlePosition: 'normal',
              },
              {
                icon: <TitleSideSmall />,
                text: 'Side small',
                actionType: 'addNewSection',
                titlePosition: 'large',
              },
              {
                icon: <TitleTopLeft />,
                text: 'Top',
                actionType: 'addNewSection',
                titlePosition: 'top',
              },
              {
                icon: <TitleTopCenter />,
                text: 'Top middle',
                actionType: 'addNewSection',
                titlePosition: 'top-middle',
              },
              {
                icon: <TitleNoTitle />,
                text: 'No Title',
                actionType: 'addNewSection',
                titlePosition: 'none',
              },
              {
                icon: <FullWidthImageIcon />,
                text: 'Full Width Image',
                actionType: 'addFullWidthImage',
                // titlePosition: 'none',
              },
            ].map(({ icon, text, ...rest }, i) => (
              <AddNewSectionCard
                key={i}
                icon={icon}
                text={text}
                onClick={() => onClick({ ...rest })}
              />
            ))}
          </Row>
        </>
      )}
    </div>
  </div>
);

const AddNewSectionCard = ({ icon, text, onClick }) => (
  <Col span={8} onClick={onClick}>
    <Card>
      {icon}
      <span className="add-section-text">{text}</span>
    </Card>
  </Col>
);

// New section element's components for droppable area
const NewSectionElement = ({ className = 'new-section', text, onClick }) => (
  <div
    className={className}
    onDrop={(event) => {
      event.preventDefault();
      const section = event.dataTransfer.getData('sectionElement');
      onClick({ actionType: 'addNewSectionElement', section });
    }}
    onDragOver={(event) => event.preventDefault()}>
    <Row gutter={32} align="middle">
      <Col span={8} offset={8}>
        <Card>
          <NewSectionIcon />
          <span className="add-section-text">{text || 'Drop your element here.'}</span>
        </Card>
      </Col>
    </Row>
  </div>
);

// New section components for droppable area
const NewSectionDrop = ({ className = 'new-section', text, onClick }) => (
  <div
    className={className}
    // onDrop={(event) => {
    //   event.preventDefault();
    //   const section = event.dataTransfer.getData('sectionElement');
    //   onClick({ actionType: 'addNewSectionElement', section });
    // }}
    // onDragOver={(event) => event.preventDefault()}
  >
    <Card>
      <NewSectionIcon />
      <span className="add-section-text">{text || 'Drop your element here.'}</span>
    </Card>
  </div>
);

const SectionWrapper = ({
  language,
  children,
  showNewSectionTopButton,
  showNewSectionBottomButton,
  showActionButtons,
  showDeleteButton,
  showReOrderButtons,
  showColumnOptions,
  setShowColumnOptions,
  showAddToLibrary,
  setShowAddToLibrary,
  saveSectionToLibrary,
  onClickItem,
  sectionName,
  wrapperType,
  showReOrderDown,
  showReOrderUp,
  zoomValue,
  updateColumnInSection,
  updateTitlePositionSection,
  titlePosition,
  sectionDroppable,
  isSection,
  showColumnsAction,
  sectionElementDroppable,
  typeOfColumns,
  isRtl,
  wixEditor,
  templateWixEditor,
  elementsInSection,
}) => {
  const [showPrependSection, togglePrependSection] = useState(false);
  const [showAppendSection, toggleApppendSection] = useState(false);
  const [hoveredColumn, setHoveredColumn] = useState(0);
  const [selectedFolderKey, setSelectedFolderKey] = useState('');
  const [tempTitlePosition, setTempTitlePosition] = useState(titlePosition);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    setTempTitlePosition(titlePosition || 'normal');
  }, [titlePosition]);

  const [form] = Form.useForm();
  const renderColumnsOverlay = () => {
    switch (hoveredColumn) {
      case 'single':
        return <Row className="section-columns-overlay-one"></Row>;
      case 'two':
        return (
          <Row className="section-columns-overlay-two">
            <Col></Col>
            <Col></Col>
          </Row>
        );
      case 'three':
        return (
          <Row className="section-columns-overlay-three">
            <Col></Col>
            <Col></Col>
            <Col></Col>
          </Row>
        );
      case 'four':
        return (
          <Row className="section-columns-overlay-four">
            <Col></Col>
            <Col></Col>
            <Col></Col>
            <Col></Col>
          </Row>
        );
      case 'twoleft':
        return (
          <Row className="section-columns-overlay-two-left">
            <Col></Col>
            <Col></Col>
          </Row>
        );
      case 'tworight':
        return (
          <Row className="section-columns-overlay-two-right">
            <Col></Col>
            <Col></Col>
          </Row>
        );
      default:
        return <></>;
    }
  };

  const onSelect = (selectedKeys) => {
    setSelectedFolderKey(selectedKeys[0]);
  };

  const handleAddToLibrarySubmit = () => {
    form
      .validateFields()
      .then((values) => {
        saveSectionToLibrary({
          newSectionName: values.newSectionName,
          sectionName,
          selectedFolderKey,
          language: language,
        });

        setSaving('save-section');
        setTimeout(() => {
          setSaving(false);
        }, 5000);

        form.resetFields();
        setSelectedFolderKey('');
        setShowAddToLibrary(''); // close save section menu
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  const getColumnShouldDisable = (titlePosition, columType, activeColumn) => {
    // if no price block esists in the section OR
    // the option is active, OR
    // column = 'single' then no need to disable
    const elementKeys = Object.keys(elementsInSection || {});

    if (!elementKeys?.length) {
      return false;
    }

    for (let i = 0; i < elementKeys.length; i++) {
      const sectionElement = elementsInSection[elementKeys[i]];
      if (
        !sectionElement.blockKeys?.length ||
        activeColumn === columType ||
        columType === 'single'
      ) {
        continue;
      }

      if (['top', 'top-middle', 'none'].includes(titlePosition)) {
        if (sectionElement?.index === 0) {
          // if price element is in column 0th, disable 'twoleft' (disable 'tworight' for hebrew)
          if ((!isRtl && columType === 'twoleft') || (isRtl && columType === 'tworight')) {
            return true;
          }
        } else if (sectionElement?.index === 1) {
          // if price element is in column 1st, disable 'tworight' (disable 'twoleft' for hebrew)
          if ((!isRtl && columType === 'tworight') || (isRtl && columType === 'twoleft')) {
            return true;
          }
        }

        /*
      if active column is not any of ['twoleft, 'tworight'] then enable both
      one of ['twoleft, 'tworight'] column will be disabled if either 1 of 2 is active
      */
        if (
          (columType === 'twoleft' && activeColumn !== 'tworight') ||
          (columType === 'tworight' && activeColumn !== 'twoleft')
        ) {
          continue;
        }
      }

      return true;
    }
  };

  return (
    <React.Fragment>
      <div
        className="section-wrapper"
        style={{
          zoom: zoomValue / 100,
        }}>
        {showPrependSection && (
          <NewSection
            onCancel={() => togglePrependSection(false)}
            onClick={({ actionType, titlePosition, ...rest }) => {
              onClickItem({
                actionType,
                titlePosition,
                sectionName,
                position: helpers.findNewSectionPosition({
                  wrapperType,
                  sectionAlignment: 'above',
                }),
                ...rest,
              });
              togglePrependSection(false);
            }}
            wixEditor={wixEditor}
            templateWixEditor={templateWixEditor}
            disableClose={showPrependSection}
          />
        )}

        {/* new section for adding elements to import proposal */}
        {sectionElementDroppable && (
          <NewSectionElement
            onClick={(rest) => {
              onClickItem({
                sectionName,
                position: helpers.findNewSectionPosition({
                  wrapperType,
                  sectionAlignment: 'above',
                }),
                ...rest,
              });
            }}
          />
        )}

        {showNewSectionTopButton && (
          <div className="new-section-container top">
            <Button
              className="new-section-button"
              onClick={() => togglePrependSection(!showPrependSection)}
              icon={showPrependSection ? <CloseIcon /> : <SectionAddIcon />}>
              {showPrependSection ? 'Close' : 'New Section'}
            </Button>
          </div>
        )}

        {showActionButtons && !showPrependSection && (
          <div className="section-action-buttons">
            <div className="group">
              {/*
                REMARK : dont show layout for importProposal
                !isSection && showColumnsAction && wrapperType !== 'importProposal'  
              */}

              {!isSection && showColumnsAction && (
                <span className={`dropdown ${showColumnOptions === sectionName ? 'active' : ''}`}>
                  <span onClick={() => onClickItem({ actionType: 'columns', sectionName })}>
                    Layout
                    <DropdownIcon fill="#005aff" />
                  </span>
                </span>
              )}

              {showColumnOptions && showColumnOptions === sectionName && (
                <div
                  className="section-action-column-options"
                  onMouseLeave={() => setShowColumnOptions('')}>
                  <div className="column-options-group layout">
                    <h4 className="title">Layout</h4>
                    {
                      // change position of title (change section layout)
                      [
                        {
                          children: <TitleSideNormal />,
                          name: 'Side normal',
                          type: 'normal',
                        },
                        { children: <TitleSideSmall />, name: 'Side small', type: 'large' },
                        { children: <TitleTopLeft />, name: 'Top', type: 'top' },
                        { children: <TitleTopCenter />, name: 'Top middle', type: 'top-middle' },
                        { children: <TitleNoTitle />, name: 'No Title', type: 'none' },
                      ].map((item) => {
                        return (
                          <ColumnItem
                            key={item.name}
                            isActive={tempTitlePosition === item.type}
                            {...item}
                            onClick={() => {
                              setTempTitlePosition(item.type);
                              updateTitlePositionSection(sectionName, item.type);
                            }}
                          />
                        );
                      })
                    }
                  </div>

                  <div
                    className="column-options-group column"
                    onMouseOut={() => setHoveredColumn(0)}>
                    <h4 className="title">Columns</h4>

                    {
                      // change no. of columns
                      [
                        { children: <ColumnsOneIcon />, type: 'single', columns: 1 },
                        { children: <ColumnsTwoIcon />, type: 'two', columns: 2 },
                        { children: <ColumnsThreeIcon />, type: 'three', columns: 3 },
                        { children: <ColumnsFourIcon />, type: 'four', columns: 4 },
                        {
                          children: <ColumnsTwoLeftIcon />,
                          type: isRtl ? 'tworight' : 'twoleft',
                          columns: 2,
                        },
                        {
                          children: <ColumnsTwoRightIcon />,
                          type: isRtl ? 'twoleft' : 'tworight',
                          columns: 2,
                        },
                      ].map((item) => {
                        return (
                          <ColumnItem
                            key={item.type}
                            isActive={typeOfColumns === item.type}
                            children={item.children}
                            onMouseOver={() => setHoveredColumn(item.type)}
                            onClick={() =>
                              updateColumnInSection(sectionName, item.columns, item.type)
                            }
                            isDisabled={getColumnShouldDisable(
                              tempTitlePosition,
                              item.type,
                              typeOfColumns
                            )}
                          />
                        );
                      })
                    }
                  </div>
                </div>
              )}
            </div>

            <div className="group">
              {showDeleteButton ? (
                <span onClick={() => onClickItem({ actionType: 'deleteSection', sectionName })}>
                  <DeleteIcon2 className="delete-icon" />
                </span>
              ) : (
                <>
                  {!isSection && (
                    <>
                      <span
                        onClick={() =>
                          onClickItem({
                            actionType: 'duplicate',
                            sectionName,
                          })
                        }>
                        Duplicate
                      </span>
                      {!wixEditor && !templateWixEditor && (
                        <span
                          onClick={() => onClickItem({ actionType: 'addToLibrary', sectionName })}>
                          Add to library
                        </span>
                      )}
                      <span
                        onClick={() => onClickItem({ actionType: 'deleteSection', sectionName })}>
                        <DeleteIcon2 className="delete-icon" />
                      </span>
                    </>
                  )}
                </>
              )}

              {showAddToLibrary && showAddToLibrary === sectionName && (
                <div
                  className="section-action-add-to-library"
                  onMouseLeave={() => setShowAddToLibrary('')}>
                  <Col>
                    <Row className="section-action-add-to-library-header">Save As</Row>
                    <Row className="section-action-add-to-library-input">
                      <Form
                        form={form}
                        layout="vertical"
                        name="form_in_modal"
                        initialValues={{
                          modifier: 'public',
                          newSectionName: '',
                        }}>
                        <Form.Item
                          name="newSectionName"
                          rules={[{ required: true, message: 'Section name is required' }]}>
                          <Input />
                        </Form.Item>
                      </Form>
                    </Row>
                    <Row className="section-action-add-to-library-list">
                      <ContentLibraryTree onSelect={onSelect} type="sections" onUpdate={() => {}} />
                    </Row>
                    <Button
                      loading={saving === 'save-section'}
                      disabled={saving === 'save-section'}
                      className="section-action-add-to-library-button"
                      onClick={handleAddToLibrarySubmit}>
                      Save Section
                    </Button>
                  </Col>
                </div>
              )}
            </div>
          </div>
        )}

        {showColumnOptions && showColumnOptions === sectionName && (
          <div id="section-columns-overlay" className={titlePosition}>
            {renderColumnsOverlay()}
          </div>
        )}

        {showReOrderButtons && !showPrependSection && (
          <div className="section-order-buttons">
            {showReOrderDown ? (
              <Tooltip title="Move Section Down">
                <span onClick={() => onClickItem({ actionType: 'moveDown', sectionName })}>
                  <ArrowDownEnabled className="delete-icon" />
                </span>
              </Tooltip>
            ) : (
              <span>
                <ArrowDownDisabled className="delete-icon" />
              </span>
            )}
            {showReOrderUp ? (
              <Tooltip title="Move Section Up">
                <span onClick={() => onClickItem({ actionType: 'moveUp', sectionName })}>
                  <ArrowUpEnabled className="delete-icon" />
                </span>
              </Tooltip>
            ) : (
              <span>
                <ArrowUpDisabled className="delete-icon" />
              </span>
            )}
            <Tooltip title="Re Order Section">
              <span onClick={() => onClickItem({ actionType: 'reOrder', sectionName })}>
                <ReOrderIcon className="delete-icon" />
              </span>
            </Tooltip>
          </div>
        )}

        {children}

        {showNewSectionBottomButton && (
          <div className="new-section-container bottom">
            <Button
              onClick={() => toggleApppendSection(!showAppendSection)}
              className="new-section-button"
              icon={showAppendSection ? <CloseIcon /> : <SectionAddIcon />}>
              {showAppendSection ? 'Close' : 'New Section'}
            </Button>
          </div>
        )}

        {showNewSectionBottomButton && (showAppendSection || sectionDroppable) && (
          <NewSection
            sectionDroppable={sectionDroppable}
            onClick={({ actionType, titlePosition, ...rest }) => {
              onClickItem({
                actionType,
                titlePosition,
                sectionName,
                position: helpers.findNewSectionPosition({
                  wrapperType,
                  sectionAlignment: 'below',
                }),
                ...rest,
              });
              toggleApppendSection(false);
            }}
            wixEditor={wixEditor}
            templateWixEditor={templateWixEditor}
          />
        )}
      </div>
    </React.Fragment>
  );
};

NewSection.propTypes = {
  onClick: PropTypes.func.isRequired,
};

SectionWrapper.defaultProps = {
  showNewSectionTopButton: true,
  showReOrderButtons: true,
  showActionButtons: true,
  showDeleteButton: false,
  showNewSectionBottomButton: true,
  sectionName: '',
  wrapperType: 'normal',
  showReOrderDown: false,
  showReOrderUp: false,
  zoomValue: 100,
  showColumnOptions: '',
  setShowColumnOptions: () => {},
  showAddToLibrary: '',
  setShowAddToLibrary: () => {},
  saveSectionToLibrary: () => {},
  titlePosition: 'normal',
  isSection: false,
  showColumnsAction: true,
  sectionElementDroppable: false,
  typeOfColumns: 'single',
  isRtl: false,
  wixEditor: false,
  templateWixEditor: false,
};

SectionWrapper.propTypes = {
  children: PropTypes.element.isRequired,
  setShowColumnOptions: PropTypes.func,
  setShowAddToLibrary: PropTypes.func,
  saveSectionToLibrary: PropTypes.func,
  showColumnOptions: PropTypes.string,
  showAddToLibrary: PropTypes.string,
  showNewSectionTopButton: PropTypes.bool,
  showNewSectionBottomButton: PropTypes.bool,
  showReOrderButtons: PropTypes.bool,
  showDeleteButton: PropTypes.bool,
  showActionButtons: PropTypes.bool,
  showReOrderDown: PropTypes.bool,
  showReOrderUp: PropTypes.bool,
  onClickItem: PropTypes.func.isRequired,
  sectionName: PropTypes.string,
  wrapperType: PropTypes.string,
  zoomValue: PropTypes.number,
  titlePosition: PropTypes.string,
  isSection: PropTypes.bool,
  showColumnsAction: PropTypes.bool,
  sectionElementDroppable: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  updateTitlePositionSection: PropTypes.func,
  typeOfColumns: PropTypes.string,
  isRtl: PropTypes.bool,
  wixEditor: PropTypes.bool,
  templateWixEditor: PropTypes.bool,
};

export default SectionWrapper;

function ColumnItem({ isActive, name, children, isDisabled, ...props }) {
  const item = (
    <div
      className={`column-item-wrapper ${isDisabled ? 'disabled' : ''} ${isActive ? 'active' : ''}`}>
      <div className="column-item" {...props}>
        {children}
        {name && <span className="name">{name}</span>}
        {isActive && <CircleTickIcon className="circle-tick-icon" />}
      </div>
    </div>
  );

  return isDisabled ? (
    <Tooltip title="Your section includes a wide element, which can't fit in a narrow column">
      {item}
    </Tooltip>
  ) : (
    item
  );
}
